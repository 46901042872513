import { useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Input } from '@lib/components/v2/Form';
import { Validation } from 'calidation';
import { localizedString } from '@languages';
import { ADDRESS_SEARCH_DEBOUNCED_DELAY_IN_MS } from '@lib/constants/addressSearchDebouncedDelay';
import Address from '@js/services/Address';
import { AddressList } from './AddressList';
import { short } from './AddressFinder.form';
import classes from './AddressFinder.style.module.scss';

const STATUS = {
  IDDLE: 'IDDLE',
  LOADING_LIST_OPTIONS: 'LOADING_LIST_OPTIONS',
  LIST_OPTIONS_LOADED: 'LIST_OPTIONS_LOADED',
  ERROR_GETTING_LIST_OPTIONS: 'ERROR_GETTING_LIST_OPTIONS',
  VALIDATING_ADDRESS: 'VALIDATING_ADDRESS',
  ERROR_VALIDATING_ADDRESS: 'ERROR_VALIDATING_ADDRESS'
};

const MIN_SEARCH_TEXT_LENGTH = 4;

export const AutocompleteAddress = ({
  initialAddress,
  initialNativeAddress,
  countryCodeIso2,
  onChange,
  disabled,
  isManualOptionOn,
  onClickManualOption,
  dataTestId
}) => {
  const { OPEN_ADDRESS_LOOK_UP_OPTIONS_IF_NO_MATCH = true } = process.env;
  const initialAddressToValidate = initialAddress || initialNativeAddress;
  const addressListPanelBottomRef = useRef();
  const setFieldRef = useRef();
  const [homeAddress, setHomeAddress] = useState(initialAddressToValidate);

  const [status, setStatus] = useState(STATUS.IDDLE);
  const [addressList, setAddressList] = useState();

  useEffect(() => {
    if (initialAddressToValidate) {
      validateInitialAddress(initialAddressToValidate);
    }
  }, []);

  const debouncedFetchAddressOptions = useMemo(
    () => debounce(fetchAddressOptions, ADDRESS_SEARCH_DEBOUNCED_DELAY_IN_MS),
    []
  );

  return (
    <div className={classes.inputWrapper}>
      <Validation config={short} initialValues={{ homeAddress, isMatch: false }}>
        {({ setField, errors }) => {
          setFieldRef.current = setField;

          if ([STATUS.VALIDATING_ADDRESS].includes(status)) {
            return (
              <div className={classes.validatingAddress}>
                {localizedString('addressFinder.validating')}
              </div>
            );
          }

          return (
            <Input
              type="textarea"
              id="homeAddress"
              required
              label={localizedString('residentalAddress')}
              placeholder={localizedString(
                'addressFinder.FLOW_V2_VERIFY_DETAILS_ADDRESS_PLACEHOLDER'
              )}
              hasError={errors.homeAddress || errors.isMatch}
              onChange={(value) => {
                setHomeAddress(value);
                setField({ isMatch: false });
                setStatus(STATUS.IDDLE);
                if (value && value.trim() !== (homeAddress || '').trim()) {
                  debouncedFetchAddressOptions({ value });
                }
              }}
              value={homeAddress}
              className={classes.inputElement}
              dataTestId={dataTestId}
              disabled={disabled}
            />
          );
        }}
      </Validation>
      <span className={classes.icon}>
        <img alt="search" src="images/icons/png/search.png" />
      </span>

      {[
        STATUS.LOADING_LIST_OPTIONS,
        STATUS.LIST_OPTIONS_LOADED,
        STATUS.ERROR_GETTING_LIST_OPTIONS
      ].includes(status) && (
        <AddressList
          loading={status === STATUS.LOADING_LIST_OPTIONS}
          hasError={status === STATUS.ERROR_GETTING_LIST_OPTIONS}
          onSelect={({ address, globalAddressKey, expand }) => {
            setAddressList(null);
            handleSelect({ address, globalAddressKey, expand });
          }}
          isManualOptionOn={isManualOptionOn}
          onClickManualOption={onClickManualOption}
          data={addressList}
        />
      )}

      <div ref={addressListPanelBottomRef} />
    </div>
  );

  async function validateInitialAddress(value) {
    if (value) {
      handleSelect({ address: value });
    }
  }

  async function fetchAddressOptions({ value = '', globalAddressKey } = {}) {
    const trimmedValue = value.trim();
    if (trimmedValue && trimmedValue.length < MIN_SEARCH_TEXT_LENGTH) {
      return;
    }

    setStatus(STATUS.LOADING_LIST_OPTIONS);

    try {
      const addresses = await Address.find({
        address: trimmedValue,
        country: countryCodeIso2,
        idHash: globalAddressKey
      });
      setAddressList(addresses);
      setStatus(STATUS.LIST_OPTIONS_LOADED);

      if (addressListPanelBottomRef.current) {
        addressListPanelBottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      setAddressList([]);
      setStatus(STATUS.ERROR_GETTING_LIST_OPTIONS);
    }
  }

  async function handleSelect({ address, globalAddressKey, expand }) {
    setStatus(STATUS.VALIDATING_ADDRESS);

    if (expand && globalAddressKey) {
      setHomeAddress(address);
      fetchAddressOptions({ globalAddressKey });
      return;
    }

    try {
      const detailedAddress = await Address.verify({
        address,
        country: countryCodeIso2,
        idHash: globalAddressKey
      });

      if (!detailedAddress.homeAddress) {
        throw new Error('Invalid address');
      }

      setHomeAddress(detailedAddress.homeAddress);
      setStatus(STATUS.IDDLE);
      setFieldRef.current({ isMatch: true });
      onChange({
        isMatch: true,
        ...detailedAddress
      });
    } catch (error) {
      console.error(error);

      if (OPEN_ADDRESS_LOOK_UP_OPTIONS_IF_NO_MATCH) {
        setHomeAddress(address);
        fetchAddressOptions({ value: address });
      } else {
        setHomeAddress('');
        setFieldRef.current({ isMatch: false, homeAddress: '' });
        setStatus(STATUS.ERROR_VALIDATING_ADDRESS);
      }
    }
  }
};

AutocompleteAddress.propTypes = {
  initialAddress: PropTypes.string,
  initialNativeAddress: PropTypes.string,
  countryCodeIso2: PropTypes.string,
  dataTestId: PropTypes.string,
  onClickManualOption: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isManualOptionOn: PropTypes.bool
};
