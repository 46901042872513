import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Liveness from 'liveness-js';
import { localizedString } from '@languages';
import position from './Animations/position.json';
import awesome from './Animations/awesome.json';
import smile from './Animations/smile.json';
import loadingAnimation from './Animations/loading.json';
import View from './View';
import { LoadingSpinner } from '../../..';

export default class Actions extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    audio: PropTypes.object,
    timeout: PropTypes.func,
    error: PropTypes.func,
    session: PropTypes.object,
    sessionId: PropTypes.func,
    onGoBack: PropTypes.func,
    loading: PropTypes.bool
  };

  static defaultProps = {
    onComplete: () => {},
    timeout: () => {},
    error: () => {},
    sessionId: () => {},
    onGoBack: () => {},
    audio: {
      play: () => {},
      pause: () => {}
    }
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.intervalId = null;

    this.handleOnComplete = this.handleOnComplete.bind(this);
    this.handleMuteAudio = this.handleMuteAudio.bind(this);
    this.handleTimeout = this.handleTimeout.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleProcess = this.handleProcess.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      step: 0,
      muted: false,
      sessionId: null,
      completed: false,
      prompts: null,

      face: false,
      smile: false,
      smileEnd: false,
      left: false,
      right: false,

      repeat: false,

      animations: {
        smile: false,
        thanks: false,
        left: false,
        right: false
      },

      toPlay: null,
      dire: Math.floor(Math.random() * 2),
      isDetecting: false,
      isRepeating: false,
      isFinishing: false,
      started: false,
      timeoutAttempt: false
    };
  }

  componentDidMount() {
    // Timeout
    this.intervalId = setTimeout(() => {
      this.handleTimeout();
    }, 60 * 1000);

    const { loading } = this.props;
    const { started } = this.state;

    if (!loading && !started) {
      this.handleProcess();
      this.setState({ started: true });
    }
  }

  componentDidUpdate() {
    const { loading } = this.props;
    const { started } = this.state;

    if (!loading && !started) {
      this.handleProcess();
      this.setState({ started: true });
    }
  }

  handleProcess() {
    let action = 'face';
    const { step } = this.state;

    if (step === 1) {
      action = 'smile';
    }

    this.setState({
      repeat: false
    });

    Liveness.detect(action, {
      retry: true,
      onRepeat: () => {
        this.setState({
          isRepeating: true,
          repeat: true
        });
        this.setState({
          isRepeating: false
        });
      },
      timeout: 10000
    })
      .then(() => {
        this.setState(({ step }) => ({
          step: step + 1,
          isRepeating: false,
          repeat: false
        }));
        setTimeout(() => {
          if (action === 'face') {
            this.handleProcess();
          }
        }, 500);
      })
      .catch(() => {
        this.handleTimeout();
      });
  }

  /**
   * Time out
   * @return {Void}
   */
  handleTimeout() {
    const { timeout } = this.props;
    const { completed, dire } = this.state;
    if (!completed) {
      this.handleClosingLiveness(() => {
        timeout(dire);
      });
    }
  }

  /**
   * To turn off/on the audio prompts.
   *
   * @return {Void}
   */
  handleMuteAudio() {
    const { muted } = this.state;
    const { audio } = this.props;
    if (audio) audio.muted = !muted;
    this.setState({ muted: !muted });
  }

  /**
   * Callback function for when the steps are completed.
   *
   * @return {Void}
   */
  handleOnComplete(type) {
    const { onComplete } = this.props;
    const { dire } = this.state;
    if (!type) {
      this.handleClosingLiveness(() => {
        onComplete(dire);
      });
      return;
    }
    this.setState(({ animations }) => ({
      animations: {
        ...animations,
        [type]: true
      }
    }));
  }

  handleClosingLiveness(callback) {
    const { error } = this.props;
    this.setState({ isFinishing: true });
    setTimeout(() => {
      Liveness.close()
        .then(() => {
          this.setState({ completed: true, isFinishing: false });
          callback();
        })
        .catch((err) => {
          this.setState({ isFinishing: false });
          error(err);
        });
    }, 500);
  }

  handleGoBack() {
    const { audio, session, onGoBack } = this.props;

    clearTimeout(this.intervalId);

    if (session) {
      session.stop();
    }
    if (audio) {
      audio.pause();
    }
    onGoBack();
  }

  render() {
    const { audio, loading } = this.props;
    const { step, muted, repeat, isRepeating, isFinishing, completed } = this.state;

    const globProps = {
      audio,
      muted,
      repeat,
      onGoBack: () => this.handleGoBack(),
      onMuteAudio: () => this.handleMuteAudio()
    };

    return (
      <div
        style={{
          background: '#000'
        }}
      >
        {/* {isLoading && <Allow />} */}
        {isFinishing && (
          <LoadingSpinner subtitle="" heading={localizedString('verifyingYourIdentity')} />
        )}
        {loading && (
          <View animation={loadingAnimation} delay={0} timeout={0}>
            {localizedString('loading')}
          </View>
        )}
        {!isRepeating && !loading && !isFinishing && !completed && (
          <div>
            {!step && (
              <View {...globProps} animation={position} audioType="position" delay={0} timeout={0}>
                {localizedString('positionFaceInFrameNewLine')}
              </View>
            )}
            {step === 1 && (
              <View
                {...globProps}
                animation={smile}
                audioType="smile"
                timeout={1.8}
                onComplete={() => this.handleOnComplete('smile')}
                delay={0}
              >
                {localizedString('giveABigSmile')}
              </View>
            )}
            {step === 2 && (
              <View
                {...globProps}
                animation={awesome}
                audioType="thanks"
                timeout={3}
                onComplete={this.handleOnComplete}
                delay={0}
              >
                {localizedString('thanks')}
              </View>
            )}
          </div>
        )}
      </div>
    );
  }
}
