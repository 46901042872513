/* eslint-disable camelcase */
import { useState } from 'react';
import PropTypes from 'prop-types';

import { ManualAddress } from './ManualAddress';
import { AutocompleteAddress } from './AutocompleteAddress';

import classes from './AddressFinder.style.module.scss';

export function AddressFinder({
  initialAddress = '',
  initialNativeAddress,
  initialAddressData = null,
  onChange = () => {},
  countryCodeIso2ToVerify,
  availableManualCountryCodesIso3,
  disabled,
  dataTestId
}) {
  const { ENABLE_ENTER_ADDRESS_MANUALLY = true } = process.env;

  const [isManualAddressFormDisplayed, setIsManualAddressFormDisplayed] = useState(
    initialAddressData?.manual ?? false
  );
  const [addressApiCalls, setAddressApiCalls] = useState(initialAddressData?.addressApiCalls || 0);

  const handleAutocompleteChange = (data) => {
    const updatedAddressApiCalls = addressApiCalls + 1;
    setAddressApiCalls(updatedAddressApiCalls);
    onChange({ ...data, addressApiCalls: updatedAddressApiCalls });
  };

  const handleManualAddressChange = (data) => {
    onChange({ ...data, addressApiCalls });
  };

  return (
    <div className={classes.wrapper}>
      {!isManualAddressFormDisplayed && (
        <AutocompleteAddress
          initialAddress={initialAddressData?.homeAddress || initialAddress}
          initialNativeAddress={initialNativeAddress}
          countryCodeIso2={countryCodeIso2ToVerify}
          isManualOptionOn={ENABLE_ENTER_ADDRESS_MANUALLY}
          onClickManualOption={() => setIsManualAddressFormDisplayed(true)}
          onChange={handleAutocompleteChange}
          disabled={disabled}
          dataTestId={dataTestId}
        />
      )}
      {isManualAddressFormDisplayed && (
        <ManualAddress
          initialFieldsData={
            initialAddressData?.manual
              ? initialAddressData
              : { selectedManualCountryCode: countryCodeIso2ToVerify }
          }
          availableIso3Countries={availableManualCountryCodesIso3}
          onChange={handleManualAddressChange}
          disabled={disabled}
        />
      )}
    </div>
  );
}

AddressFinder.propTypes = {
  initialAddress: PropTypes.string,
  initialNativeAddress: PropTypes.string,
  initialAddressData: PropTypes.object,
  onChange: PropTypes.func,
  countryCodeIso2ToVerify: PropTypes.string,
  availableManualCountryCodesIso3: PropTypes.array,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string
};
