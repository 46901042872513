import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { localizedString } from '@languages';
import classes from './NoMobileDeviceWrapper.style.module.scss';

export const NoMobileDeviceWrapper = ({
  title = localizedString('mobileIDVerification.title'),
  children
}) => {
  const { APP_LOGO } = process.env;
  const [finalLogo, setFinalLogo] = useState(`images/logos/${APP_LOGO}`);

  useEffect(() => {
    const logo = localStorage.getItem(`${window.location.host}_app_logo`);
    if (logo) {
      setFinalLogo(logo);
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <img alt="" className={classNames(classes.logo, 'b-logo')} src={finalLogo} />
        <div className={classes.block}>
          <div className={classes.title}>{title}</div>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

NoMobileDeviceWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};
