import Animation from './Animation';

export default Animation;

const { FLOW_V2_ANIMATION_FOLDER_BASE_URL = '/animations/FLOW_V2' } = process.env;
const { VOI_V2_ANIMATION_FOLDER_BASE_URL = '/animations/VOI_V2' } = process.env;

export const FLOW_V2_ANIMATION_FILE_URLS = {
  CARD: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_01_var_01.json`,
  PASSPORT: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_passport.json`,
  REVIEW: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_data.json`,
  FACE_ID: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_face_id.json`,
  BACK_CARD: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_back_card.json`,
  CLOSE_FAR: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_close_far.json`,
  LAPTOP: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_laptop.json`,
  LAMP: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_lamp.json`
};

export const VOI_V2_ANIMATION_FILE_URLS = {
  CARD: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_01_var_01.json`,
  PASSPORT: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_passport.json`,
  REVIEW: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_data.json`,
  FACE_ID: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_face_id.json`,
  BACK_CARD: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_back_card.json`,
  CLOSE_FAR: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_close_far.json`,
  LAPTOP: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_laptop.json`,
  LAMP: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_lamp.json`,
  CERTIFICATES: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_certs.json`,
  CAPTURE_EXTRA: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_capture_extra.json`
};
