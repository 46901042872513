import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { SendSMS, QRVerificationPage, OnlyMobileAllowed } from '@containers';
import { LoadingSpinner, Message } from '@FLOW_V2_FLOW/components';
import { LockedTransactionUrl } from '@FLOW_V2_FLOW/errors';
import { getQueryStringParams, generateId } from '@js/lib/Utils';
import { localizedString } from '@languages';
import { NON_MOBILE_DEVICE_SCREEN_TYPES } from '@lib/constants/nonMobileDeviceScreenTypes';
import { isMobile } from 'react-device-detect';
import AppConfigAction from '../store/actions/appConfig';
import { setIpCountryCodeAction } from '../store/actions/ipInfo';
import { AppContainer as AppFlowV2Container } from '../_FLOW_V2_FLOW/containers';
import { AppContainer as AppVOIFlowV2Container } from '../_VOIFLOW_V2_FLOW/containers';
import { AppContainer as AppLiveOnlyContainer } from '../_LIVEONLY_FLOW/containers';
import { AppContainer as AppQOnlyContainer } from '../_Q_ONLY_FLOW/containers';
import APIs from '../services/APIs';

import '../styles/base.scss';

class AppView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verify: false,
      flowType: '',
      isLoading: true,
      isCompleted: false,
      transStatus: '',
      questionnaire: null,
      hasLivenessScreen: true,
      error: null,
      isIncorrectUrl: false
    };
  }

  componentDidMount() {
    const { setAppConfig, setIpCountryCode } = this.props;

    const countryRequestPromise = APIs.country()
      .then((countryInfoResponse) => {
        const { country_code: countryCode } = countryInfoResponse;
        setIpCountryCode(countryCode);
      })
      .catch(() => {
        this.setState({ isIncorrectUrl: true });
      });

    if (document.body.dataset.primary) {
      document.documentElement.style.setProperty('--primary', document.body.dataset.primary);
      document.documentElement.style.setProperty('--loading-bg', document.body.dataset.primary);
      document.documentElement.style.setProperty(
        '--loading-progress-bar-incomplete',
        document.body.dataset.primary
      );
      document.documentElement.style.setProperty('--loading-progress-bar', '#fff');
      document.documentElement.style.setProperty('--loading-dot', '#fff');
      document.documentElement.style.setProperty('--loading-text-color', '#fff');
    }
    if (document.body.dataset.secondary) {
      document.documentElement.style.setProperty('--secondary', document.body.dataset.secondary);
    }

    const { UNIVERSAL_URL_PATH = '/verify' } = process.env;
    let config = {};

    let browserToken = sessionStorage.getItem('browser_session_token');
    sessionStorage.clear();

    if (browserToken) {
      sessionStorage.setItem('browser_session_token', browserToken);
    } else {
      sessionStorage.setItem('browser_session_token', generateId(20));
    }

    browserToken = sessionStorage.getItem('browser_session_token');

    const lockTxnRequestPromise = APIs.sendBrowserToken(browserToken)
      .then((result) => {
        if (result.status === 'error') {
          this.setState({
            error: {
              component: LockedTransactionUrl,
              props: {
                transactionUrlLockExpiry: result.transactionUrlLockExpiry
              }
            }
          });
        }
      })
      .catch(() => {});

    let checkTxnRequestPromise;

    if (window.location.pathname === UNIVERSAL_URL_PATH) {
      const queryParams = getQueryStringParams();
      const state = {};
      if ('flowType' in queryParams && queryParams.flowType) {
        state.flowType = queryParams.flowType;
      }
      this.setState(state);
    } else {
      checkTxnRequestPromise = APIs.checkTransactionType()
        .then((result) => {
          // eslint-disable-next-line no-console
          console.log('TransactionCheckResult:', result);
          const {
            status,
            verify,
            flowType = 'FLOW_V2',
            isCompleted = false,
            questionnaire = null,
            transStatus,
            dataProvider = null,
            hasLivenessScreen = true,
            appConfig = {},
            user_resend_count: userResendCount = 0
          } = result;

          this.setState({ userResendCount });

          if (
            status !== 'success' ||
            transStatus === 'COMPLETED' ||
            transStatus === 'EXPIRED' ||
            transStatus === 'CANCELLED' ||
            transStatus === '404'
          ) {
            this.setState({
              flowType: 'FLOW_V2',
              transStatus: status !== 'success' ? '404' : transStatus
            });
          } else {
            this.setState({
              verify,
              flowType,
              isCompleted,
              transStatus,
              questionnaire,
              hasLivenessScreen
            });
          }

          // Set Global CSS Vars
          if (appConfig.primary) {
            document.documentElement.style.setProperty('--primary', appConfig.primary);
            document.documentElement.style.setProperty('--loading-bg', appConfig.primary);
            document.documentElement.style.setProperty(
              '--loading-progress-bar-incomplete',
              appConfig.primary
            );
            document.documentElement.style.setProperty('--loading-progress-bar', '#fff');
            document.documentElement.style.setProperty('--loading-dot', '#fff');
            document.documentElement.style.setProperty('--loading-text-color', '#fff');
            document.documentElement.style.setProperty('--loading-spinner-dot1', appConfig.primary);
            document.documentElement.style.setProperty('--loading-spinner-dot3', appConfig.primary);
          }

          if (appConfig.secondary) {
            document.documentElement.style.setProperty('--secondary', appConfig.secondary);
            document.documentElement.style.setProperty(
              '--loading-spinner-dot2',
              appConfig.secondary
            );
            document.documentElement.style.setProperty(
              '--loading-spinner-dot4',
              appConfig.secondary
            );
          }
          if (appConfig.body) {
            document.documentElement.style.setProperty('--body-text-color', appConfig.body);
            document.documentElement.style.setProperty('--heading-text-color', appConfig.body);
          }
          if (appConfig.font) {
            document.documentElement.style.setProperty('--base-font-family', appConfig.font);
          }
          if (appConfig.heading_font) {
            document.documentElement.style.setProperty(
              '--heading-font-family',
              appConfig.heading_font
            );
          }

          // Store the APP settings to store
          config = { ...config, ...appConfig, dataProvider };
          setAppConfig(config);
          this.setState({
            isLoading: false
          });
        })
        .catch(() => {
          this.setState({
            flowType: 'FLOW_V2',
            transStatus: '404'
          });
        });
    }

    Promise.allSettled([countryRequestPromise, lockTxnRequestPromise, checkTxnRequestPromise]).then(
      () => {
        this.setState({
          isLoading: false
        });
      }
    );
  }

  render() {
    document.documentElement.className = isMobile ? 'mobile' : 'desktop';

    const { NON_MOBILE_DEVICE_FALLBACK } = process.env;

    const {
      flowType,
      verify,
      isLoading,
      isCompleted,
      transStatus,
      questionnaire,
      hasLivenessScreen,
      error,
      isIncorrectUrl,
      userResendCount
    } = this.state;

    const { component: Error, props: errorProps } = error || {};

    if (isLoading) {
      return <LoadingSpinner heading={localizedString('pleaseWait')} />;
    }

    if (!isMobile) {
      if (isIncorrectUrl) {
        return (
          <Message title={localizedString('app.FLOW_V2_INCORRECT_URL_ALERT_TITLE')} issue>
            {parse(localizedString('app.FLOW_V2_INCORRECT_URL_ALERT_DESCRIPTION'))}
          </Message>
        );
      }

      if (NON_MOBILE_DEVICE_SCREEN_TYPES.SMS === NON_MOBILE_DEVICE_FALLBACK) {
        return <SendSMS resendCount={userResendCount} />;
      }

      if (NON_MOBILE_DEVICE_SCREEN_TYPES.QR === NON_MOBILE_DEVICE_FALLBACK) {
        return <QRVerificationPage />;
      }

      return <OnlyMobileAllowed />;
    }

    if (error) {
      return <Error {...errorProps} />;
    }

    if (flowType === 'LIVE_ONLY') {
      return <AppLiveOnlyContainer isCompleted={isCompleted} flowType={flowType} verify={verify} />;
    }

    if (flowType === 'FLOW_V2' || flowType === 'DATA_ONLY') {
      return (
        <AppFlowV2Container
          transStatus={transStatus}
          flowType={flowType}
          verify={verify}
          hasLivenessScreen={hasLivenessScreen}
          questionnaire={questionnaire}
        />
      );
    }

    if (flowType === 'VOI_FLOW_V2') {
      return (
        <AppVOIFlowV2Container transStatus={transStatus} flowType={flowType} verify={verify} />
      );
    }

    if (flowType === 'Q_ONLY') {
      return (
        <AppQOnlyContainer
          isCompleted={isCompleted}
          flowType={flowType}
          verify={verify}
          questionnaire={questionnaire}
        />
      );
    }

    return (
      <AppFlowV2Container
        transStatus="404"
        flowType={flowType}
        verify={verify}
        hasLivenessScreen={hasLivenessScreen}
        questionnaire={questionnaire}
      />
    );
  }
}

AppView.propTypes = {
  setAppConfig: PropTypes.func,
  setIpCountryCode: PropTypes.func
};

export default connect(null, mapDispatchToProps)(AppView);

function mapDispatchToProps(dispatch) {
  return {
    setAppConfig: (data) => dispatch(AppConfigAction.setAppConfig(data)),
    setIpCountryCode: (data) => dispatch(setIpCountryCodeAction(data))
  };
}
