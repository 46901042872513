import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './Modal.style.module.scss';
import { Button, ButtonGroup } from '..';

/**
 * A sample Modal
 */
const Modal = ({ children, isOpen, small, heading, buttons, className }) => {
  return (
    <div
      className={classNames(classes.wrapper, isOpen && classes.open, className)}
      aria-modal="true"
    >
      <div
        className={classNames(classes.modalContent, isOpen && classes.open, small && classes.small)}
      >
        <div className={classNames(classes.dialog)}>
          {heading && <div className={classes.heading}>{heading}</div>}
          {children}
          {buttons.length > 0 && (
            <ButtonGroup btnCount={buttons.length} stacked>
              {buttons.map((button) => (
                <Button key={button.label} {...button} />
              ))}
            </ButtonGroup>
          )}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  small: PropTypes.bool,
  heading: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      shadow: PropTypes.bool,
      large: PropTypes.bool
    })
  )
};

Modal.defaultProps = {
  isOpen: false
};

export default Modal;
