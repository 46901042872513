import parse from 'html-react-parser';
import { localizedString } from '@languages';
import { NoMobileDeviceWrapper } from '@layouts/NoMobileDeviceWrapper';

export const OnlyMobileAllowed = () => {
  const { DESKTOP_SMS_DISABLED_CONTENT, DESKTOP_SMS_DISABLED_TITLE } = process.env;

  const defaultContent = `
    <h5 className="text-black-50">
        ${localizedString('stop')}!
    </h5>
    ${localizedString('accessLinkSentToMobile')}
  `;

  return (
    <NoMobileDeviceWrapper title={DESKTOP_SMS_DISABLED_TITLE}>
      <div className="p-4 text-center">
        {DESKTOP_SMS_DISABLED_CONTENT ? parse(DESKTOP_SMS_DISABLED_CONTENT) : parse(defaultContent)}
      </div>
    </NoMobileDeviceWrapper>
  );
};
